import { Spinner } from '@/components/Spinner'
import { ScrollRefContext } from '@/contexts'
import type { UserPaymentsTransactionsQuery } from '@/gql/graphql.ts'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Transaction } from './Transaction'

export const Transactions: React.FC<{
  loadMore: (page: number) => void
  hasMore: boolean
  transactions: UserPaymentsTransactionsQuery['payments']
}> = ({ loadMore, hasMore, transactions }) => {
  const scrollRef = useContext(ScrollRefContext)!

  return (
    <div className="w-full">
      <div className="flex flex-row mb-2 text-gray-shuttle text-xs max-md:hidden">
        <div className="w-[6%]" />
        <div className="w-[22%]">
          <T keyName="components.payments.transactions.headers.name" />
        </div>
        <div className="w-[22%]">
          <T keyName="components.payments.transactions.headers.service" />
        </div>
        <div className="w-[22%]">
          <T keyName="components.payments.transactions.headers.paymentDate" />
        </div>
        <div className="w-[22%]">
          <T keyName="components.payments.transactions.headers.type" />
        </div>
        <div className="w-[6%] text-end">
          <T keyName="components.payments.transactions.headers.amount" />
        </div>
      </div>

      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          useWindow={false}
          getScrollParent={() => scrollRef.current}
          initialLoad={true}
          hasMore={hasMore}
          loader={<Spinner small key={0} />}
        >
          {transactions.map(
            ({
              id,
              sender,
              receiver,
              amount,
              commission,
              type,
              service,
              status,
              createdAt
            }) => (
              <Transaction
                key={id}
                type={type}
                sender={sender!}
                receiver={receiver!}
                amount={amount}
                commission={commission}
                serviceName={service?.provider?.name ?? ''}
                status={status}
                createdAt={createdAt}
                serviceTag={service?.provider?.tag ?? ''}
                serviceTagColor={service?.provider?.tagColor ?? ''}
              />
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}
