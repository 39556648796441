import { EmptyTransactions } from '@/assets/illustrations'
import { PopupOutlet } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { Transactions } from '@/components/Transactions/Transactions'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { useQuery } from '@apollo/client'
import { T } from '@tolgee/react'
import _ from 'lodash'
import { type ReactElement, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

const JOIN_PAYMENTS = gql(`
  query joinPaymentsTransactions(
    $serviceId: String!
    $senderId: String!
    $skip: Float
    $after: TransactionWhereUniqueInput
    $first: Float!
  ) {
    transactions(
      where: { serviceId: $serviceId, senderId: $senderId, type: MONEY_IN }
      orderBy: { createdAt: desc }
      skip: $skip
      after: $after
      first: $first
    ) {
      id
      sender {
        id
        avatar
        fullName
      }
      receiver {
        id
        avatar
        fullName
      }
      amount
      type
      service {
        provider {
          name
          tag
          tagColor
        }
      }
      createdAt
      commission
      status
    }
  }
`)

const OFFER_PAYMENTS = gql(`
  query offerPaymentsTransactions(
    $serviceId: String!
    $skip: Float
    $after: TransactionWhereUniqueInput
    $first: Float!
  ) {
    transactions: offerTransactions(
      where: { serviceId: $serviceId }
      orderBy: { createdAt: desc }
      first: $first
      skip: $skip
      after: $after
    ) {
      id
      sender {
        id
        avatar
        fullName
      }
      receiver {
        id
        avatar
        fullName
      }
      amount
      type
      service {
        provider {
          name
          tag
          tagColor
        }
      }
      createdAt
      commission
      status
    }
  }
`)

export const SubscriptionTransactions: React.FC<{ type: 'join' | 'offer' }> = ({
  type
}) => {
  const { currentUser } = useContext(AuthContext)!
  invariant(currentUser, 'User must be logged in')

  const { id } = useParams<{ id: string }>()

  const { data, loading, fetchMore } = useQuery(
    type === 'join' ? JOIN_PAYMENTS : OFFER_PAYMENTS,
    {
      variables: {
        serviceId: id!,
        senderId: currentUser.id,
        first: 20
      }
    }
  )

  const [transactions, setTransactions] = useState<
    NonNullable<typeof data>['transactions']
  >([])
  const [hasMore, setHasMore] = useState(true)

  const loadMore = async () => {
    const { data } = await fetchMore({
      variables: {
        after: {
          id: transactions[transactions.length - 1].id
        },
        skip: 1
      }
    })
    if (!data || _.isEmpty(data.transactions)) {
      setHasMore(false)
      return
    }

    const newTransactions = _.chain(transactions)
      .concat(data.transactions)
      .sortedUniqBy('createdAt')
      .value()
    setTransactions(newTransactions)
  }

  useEffect(() => {
    if (data) {
      setTransactions(data.transactions)
    }
  }, [data])

  if (loading) return <Spinner />

  let transactionsContainer: ReactElement
  if (transactions.length > 0) {
    transactionsContainer = (
      <Transactions
        loadMore={loadMore}
        hasMore={hasMore}
        transactions={transactions}
      />
    )
  } else {
    transactionsContainer = (
      <div className="mt-20 max-md:mt-0 text-center">
        <div className="mb-8 flex justify-center">
          <img src={EmptyTransactions} alt="" />
        </div>
        <div className="text-2xl text-gray-shuttle-soft mb-2">
          <T keyName="emptyStates.transactions.oops" />
        </div>
        <div className="text-lg text-gray-shuttle-soft mb-4">
          <T keyName="emptyStates.transactions.text" />
        </div>
        <Link
          to="popup/invite-friends"
          className="text-blue-dodger cursor-pointer"
        >
          <T keyName="emptyStates.transactions.invite" />
        </Link>
      </div>
    )
  }

  return (
    <div>
      {transactionsContainer}
      <PopupOutlet />
    </div>
  )
}
